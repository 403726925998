import { IonContent, IonPage } from '@ionic/react'
import React from 'react'

import './Index.css'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../hooks'

const Index = (): JSX.Element => {
    const loggedIn = useAppSelector((state) => state.session.loggedIn)
    const user = useAppSelector((state) => state.session.user)

    const renderRedirectLink = () => {
        if (loggedIn && user) {
            return (
                <>
                    <p>
                        Eingeloggt als: {user.firstName} {user.lastName} (
                        {user.email})
                    </p>
                    <Link to="/dashboard">Zum Dashboard</Link>
                </>
            )
        }
        return <Link to="/login">Zum Login</Link>
    }

    return (
        <IonPage>
            <IonContent>
                <div className="index-main">
                    <div className="ion-text-center ion-padding ion-margin">
                        <h1>Startseite</h1>
                        {renderRedirectLink()}
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Index
