import React, { JSX } from 'react'
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonFab,
    IonHeader,
    IonIcon,
    IonList,
    IonListHeader,
    IonSearchbar,
    IonTitle,
    IonToolbar,
    SearchbarCustomEvent,
} from '@ionic/react'
import { Virtuoso } from 'react-virtuoso'
import IcfIcdSearchEntry from '../../components/IcfIcd/IcfIcdSearchEntry'
import { saveOutline } from 'ionicons/icons'
import { SearchResultIcf } from '../../utils/IcfIcdType'

interface IcfSearchAndSelectionProps {
    hrefBackButton: string
    handleSearch: (e: SearchbarCustomEvent) => void
    searchResult: SearchResultIcf
    caseIcfs: string[]
    handleCheckboxSelection: (icf: string, checked?: boolean) => void
    handleSubmit: () => Promise<void>
    submitting: boolean
    submitButtonText?: string
}

const IcfSearchAndSelection = ({
    hrefBackButton,
    handleSearch,
    searchResult,
    caseIcfs,
    handleCheckboxSelection,
    handleSubmit,
    submitting,
    submitButtonText,
}: IcfSearchAndSelectionProps): JSX.Element => {
    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            text=""
                            defaultHref={hrefBackButton}
                        ></IonBackButton>
                    </IonButtons>
                    <IonTitle>ICFs</IonTitle>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar
                        autocapitalize="off"
                        debounce={1000}
                        onIonInput={handleSearch}
                    ></IonSearchbar>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <IonList
                        style={{
                            flex: 1,
                            marginInlineStart: '16px',
                            marginInlineEnd: '16px',
                            marginTop: '16px',
                            marginBottom: '16px',
                            borderRadius: '10px',
                        }}
                    >
                        <Virtuoso
                            style={{ height: '100%' }}
                            totalCount={searchResult.itemCount}
                            itemContent={(index) => {
                                return (
                                    <div style={{ minHeight: '44px' }}>
                                        {index ===
                                            searchResult
                                                .separationIndices[0] && (
                                            <IonListHeader>
                                                Kapitel
                                            </IonListHeader>
                                        )}
                                        {index ===
                                            searchResult
                                                .separationIndices[1] && (
                                            <IonListHeader>
                                                Gruppe
                                            </IonListHeader>
                                        )}
                                        {index ===
                                            searchResult
                                                .separationIndices[2] && (
                                            <IonListHeader>
                                                Kategorie
                                            </IonListHeader>
                                        )}
                                        {index ===
                                            searchResult
                                                .separationIndices[3] && (
                                            <IonListHeader>
                                                Subkategorie 1
                                            </IonListHeader>
                                        )}
                                        {index ===
                                            searchResult
                                                .separationIndices[4] && (
                                            <IonListHeader>
                                                Subkategorie 2
                                            </IonListHeader>
                                        )}
                                        <IcfIcdSearchEntry
                                            entry={searchResult.items[index]}
                                            selected={caseIcfs.includes(
                                                searchResult.items[index].code
                                            )}
                                            onCheckboxSelection={
                                                handleCheckboxSelection
                                            }
                                        />
                                    </div>
                                )
                            }}
                        />
                    </IonList>
                </div>
                <IonFab slot="fixed" horizontal="center" vertical="bottom">
                    <IonButton
                        shape="round"
                        className="ion-margin-bottom"
                        onClick={handleSubmit}
                        disabled={submitting}
                    >
                        <IonIcon slot="start" icon={saveOutline} />
                        {submitButtonText ?? 'Speichern'}
                    </IonButton>
                </IonFab>
            </IonContent>
        </>
    )
}

export default IcfSearchAndSelection
