import React, { JSX, useCallback, useEffect, useState } from 'react'
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonList,
    IonListHeader,
    IonModal,
    IonSearchbar,
    IonTitle,
    IonToolbar,
    isPlatform,
    SearchbarCustomEvent,
} from '@ionic/react'
import { Virtuoso } from 'react-virtuoso'
import { chevronBackOutline } from 'ionicons/icons'
import IcfIcdSearchEntry from '../../components/IcfIcd/IcfIcdSearchEntry'
import { searchIcf } from '../../utils/IcfIcdHandler'
import { SearchResultIcf } from '../../utils/IcfIcdType'

interface IcfAddModalProps {
    open: boolean
    onClose: () => void
    onCheckboxSelection: (icf: string, checked?: boolean) => void
    selectedIcfs: string[]
}

const IcfAddModal = ({
    open,
    onClose,
    onCheckboxSelection,
    selectedIcfs,
}: IcfAddModalProps): JSX.Element => {
    const [searchResult, setSearchResult] = useState<SearchResultIcf>({
        chapter: [],
        block: [],
        fourdigit: [],
        fivedigit: [],
        sixdigit: [],
        items: [],
        itemCount: 0,
        separationIndices: [],
    })

    const handleSearch = useCallback((e: SearchbarCustomEvent) => {
        let filter = searchIcf(e.detail.value ?? '')
        setSearchResult(filter)
    }, [])

    useEffect(() => {
        let filter = searchIcf('')
        setSearchResult(filter)
    }, [])

    return (
        <IonModal
            isOpen={open}
            onDidDismiss={onClose}
            className={
                isPlatform('tablet') || isPlatform('desktop')
                    ? 'modal-fullscreen'
                    : undefined
            }
        >
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="medium" onClick={onClose}>
                            <IonIcon
                                icon={chevronBackOutline}
                                color="primary"
                            />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>ICF</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={onClose}>Speichern</IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar
                        autocapitalize="off"
                        debounce={1000}
                        onIonInput={handleSearch}
                    />
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <IonList
                        style={{
                            flex: 1,
                            marginInlineStart: '16px',
                            marginInlineEnd: '16px',
                            marginTop: '16px',
                            marginBottom: '16px',
                            borderRadius: '10px',
                        }}
                    >
                        <Virtuoso
                            style={{ height: '100%' }}
                            totalCount={searchResult.itemCount}
                            itemContent={(index) => {
                                return (
                                    <div style={{ minHeight: '44px' }}>
                                        {index ===
                                            searchResult
                                                .separationIndices[0] && (
                                            <IonListHeader>
                                                Kapitel
                                            </IonListHeader>
                                        )}
                                        {index ===
                                            searchResult
                                                .separationIndices[1] && (
                                            <IonListHeader>
                                                Gruppe
                                            </IonListHeader>
                                        )}
                                        {index ===
                                            searchResult
                                                .separationIndices[2] && (
                                            <IonListHeader>
                                                Kategorie
                                            </IonListHeader>
                                        )}
                                        {index ===
                                            searchResult
                                                .separationIndices[3] && (
                                            <IonListHeader>
                                                Subkategorie 1
                                            </IonListHeader>
                                        )}
                                        {index ===
                                            searchResult
                                                .separationIndices[4] && (
                                            <IonListHeader>
                                                Subkategorie 2
                                            </IonListHeader>
                                        )}
                                        <IcfIcdSearchEntry
                                            entry={searchResult.items[index]}
                                            selected={selectedIcfs?.includes(
                                                searchResult.items[index].code
                                            )}
                                            onCheckboxSelection={
                                                onCheckboxSelection
                                            }
                                        />
                                    </div>
                                )
                            }}
                        />
                    </IonList>
                </div>
            </IonContent>
        </IonModal>
    )
}

export default IcfAddModal
