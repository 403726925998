import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Session } from '../../utils/SessionType'
import axiosInstance, { API_ROOT } from '../../utils/ApiConfig'
import { User } from '../User/UserType'
import { remove, set } from '../../data/IonicStorage'
import { LoginForm } from '../Login/Login'
import { FormikValues } from 'formik'
import { IApiResponseMessage } from '../../utils/Interfaces/ApiInterface'

const initialState = {
    loading: true,
    loggedIn: false,
} as Session

export const fetchSession = createAsyncThunk('session/getSession', async () => {
    const response = await axiosInstance.get(API_ROOT + '/session', {
        withCredentials: true,
    })

    if (response.status !== 200) {
        await remove('jwt')
    }

    const { loggedIn, user } = response.data.data
    return { user, loggedIn }
})

interface LoginApiResponse {
    data: {
        loggedIn: boolean
        user: User
        message: IApiResponseMessage
        accessToken: string
    }
}

export const loginUser = createAsyncThunk(
    'auth/login',
    async (data: LoginForm) => {
        //const header = await getHeader();
        const response = await axiosInstance.post<LoginApiResponse>(
            '/login',
            data
        )
        set('jwt', response.data.data.accessToken)

        const { loggedIn, user, message } = response.data.data
        return {
            user,
            loggedIn,
            message,
        }
    }
)

//TODO fix route to use user controller in backend
export const registerUser = createAsyncThunk(
    'auth/register',
    async (params: { data: FormikValues }) => {
        const { data } = params

        const response = await axiosInstance.post('/register', data)
        return { message: response.data.message }
    }
)

export const logoutUser = createAsyncThunk('auth/logout', async () => {
    const response = await axiosInstance.post('/logout')
    await remove('jwt')

    if (response.status === 200) {
        return { loggedIn: false, message: response.data.message }
    }
})

const sessionSlice = createSlice({
    name: 'session',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSession.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchSession.fulfilled, (state, action) => {
            const { user, loggedIn } = action.payload

            state.loading = false
            state.loggedIn = loggedIn
            state.user = user
        })
        builder.addCase(fetchSession.rejected, (state) => {
            state.loading = false
            state.loggedIn = false
            state.user = undefined
        })
        builder.addCase(loginUser.fulfilled, (state, action) => {
            const { user, loggedIn } = action.payload

            state.loggedIn = loggedIn
            state.user = user
        })
        builder.addCase(logoutUser.fulfilled, (state) => {
            return { loggedIn: false, loading: false }
        })
    },
})

export default sessionSlice.reducer
