import { IcfIcdEntry } from '../../utils/IcfIcdType'
import { IonButton, IonIcon, IonItem, IonLabel, IonText } from '@ionic/react'
import { informationCircleOutline, trashOutline } from 'ionicons/icons'
import React, { JSX, memo, useMemo } from 'react'
import { exactSearch } from '../../utils/IcfIcdHandler'
import { IcfIcdModeEnum } from '../../utils/Enum/IcfIcdEnum'

interface IcfIcdItemProps {
    entry: IcfIcdEntry | string
    onInfo: (entry: IcfIcdEntry) => void
    onDelete: (entry: IcfIcdEntry) => void
}

const IcfIcdItem = ({
    entry,
    onInfo,
    onDelete,
}: IcfIcdItemProps): JSX.Element => {
    const exactEntry = useMemo(() => {
        if (typeof entry === 'string') {
            return exactSearch(entry, IcfIcdModeEnum.MODE_ICF)
        }
        return entry
    }, [entry])

    if (!exactEntry) {
        return <></>
    }

    return (
        <IonItem>
            <IonLabel>
                <IonText>{`${exactEntry.code} - ${exactEntry.label}`}</IonText>
            </IonLabel>
            <IonButton
                id={'info-' + exactEntry.code}
                onClick={() => onInfo(exactEntry)}
                shape="round"
                fill="clear"
                slot="end"
            >
                <IonIcon slot="icon-only" icon={informationCircleOutline} />
            </IonButton>
            <IonButton
                onClick={() => onDelete(exactEntry)}
                shape="round"
                fill="clear"
                slot="end"
            >
                <IonIcon color="danger" slot="icon-only" icon={trashOutline} />
            </IonButton>
        </IonItem>
    )
}

export default memo(IcfIcdItem)
