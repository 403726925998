import React, { FC, useEffect } from 'react'
import { IonRouterOutlet } from '@ionic/react'
import {
    Route,
    RouteComponentProps,
    useParams,
    useRouteMatch,
} from 'react-router-dom'
import CaseOverview from '../CaseOverview'
import DocumentationIndex from '../../Documentation/DocumentationIndex'
import AimIndex from '../../Aim/AimIndex'
import TestEvaluationIndex from '../../TestEvaluation/TestEvaluationIndex'
import { useAppDispatch } from '../../../hooks'
import { fetchCaseById } from '../CaseSlice'
import { isEqual } from 'lodash'
import IcdSelection from '../../ICD/IcdSelection'
import IcfSelection from '../../ICF/IcfSelection'
import InspectionIndex from '../../Inspection/InspectionIndex'
import TestSelectionTestSelection from '../../TestSelection/TestSelectionTestSelection'
import AnamnesticCaseAnalysisEdit from '../../AnamnesticCaseAnalysis/AnamnesticCaseAnalysisEdit'

const CaseLoader: FC<RouteComponentProps> = () => {
    const { path } = useRouteMatch()

    const { caseId, patientId } = useParams<{
        caseId: string
        patientId: string
    }>()
    const dispatch = useAppDispatch()
    const isNewCase = isEqual(caseId, 'new')

    useEffect(() => {
        if (!isNewCase) {
            dispatch(fetchCaseById({ id: caseId, patientId }))
        }
    }, [caseId, dispatch, isNewCase, patientId])

    return (
        <IonRouterOutlet>
            <Route exact path={`${path}/`} component={CaseOverview} />
            <Route
                path={`${path}/documentations`}
                component={DocumentationIndex}
            />
            <Route path={`${path}/inspection`} component={InspectionIndex} />
            <Route path={`${path}/icd`} component={IcdSelection} />
            <Route path={`${path}/icf`} component={IcfSelection} />
            <Route
                path={`${path}/anamnesticcaseanalysis`}
                component={AnamnesticCaseAnalysisEdit}
            />
            <Route path={`${path}/aims`} component={AimIndex} />
            <Route
                path={`${path}/testevaluations`}
                component={TestEvaluationIndex}
            />
            <Route
                path={`${path}/testselection`}
                component={TestSelectionTestSelection}
            />
        </IonRouterOutlet>
    )
}

export default CaseLoader
