import React, { JSX, useCallback, useEffect, useState } from 'react'
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
    createPatient,
    fetchPatientById,
    selectPatientById,
    updatePatient,
} from './PatientSlice'
import { Formik, FormikValues } from 'formik'
import { useHistory } from 'react-router'

const PatientEditPage = (): JSX.Element => {
    const { patientId } = useParams<{ patientId: string }>()
    const patient = useAppSelector((state) =>
        selectPatientById(state, +patientId)
    )
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [birthdayPickerOpen, setBirthdayPickerOpen] = useState<boolean>(false)

    const isNew = !patient

    const handleSubmit = useCallback(
        async (values: FormikValues) => {
            if (isNew) {
                await dispatch(createPatient({ data: values }))
            } else {
                await dispatch(updatePatient({ data: values, id: patientId }))
            }
            history.push('/patients')
        },
        [dispatch, history, isNew, patientId]
    )

    useEffect(() => {
        if (!isNew) {
            dispatch(fetchPatientById({ id: +patientId }))
        }
    }, [dispatch, isNew, patientId])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            text=""
                            defaultHref={`/patients/`}
                        ></IonBackButton>
                    </IonButtons>
                    <IonTitle>
                        {isNew
                            ? 'Neuen Patienten anlegen'
                            : `${patient.firstName} ${patient.lastName} bearbeiten`}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <Formik
                    enableReinitialize
                    initialValues={
                        !isNew ? ({ ...patient } as FormikValues) : {}
                    }
                    onSubmit={handleSubmit}
                >
                    {({ values, handleChange, submitForm, setFieldValue }) => (
                        <>
                            <IonList inset>
                                <IonListHeader>
                                    <IonLabel>Persönliche Daten</IonLabel>
                                </IonListHeader>
                                <IonItem>
                                    <IonInput
                                        name="firstName"
                                        value={
                                            values.firstName
                                                ? values.firstName
                                                : ''
                                        }
                                        onIonChange={handleChange}
                                        label="Vorname"
                                        labelPlacement="fixed"
                                    ></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonInput
                                        name="lastName"
                                        value={
                                            values.lastName
                                                ? values.lastName
                                                : ''
                                        }
                                        onIonChange={handleChange}
                                        label="Nachname"
                                        labelPlacement="fixed"
                                    ></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonSelect
                                        onIonChange={(e) => {
                                            setFieldValue(
                                                'gender',
                                                e.detail.value,
                                                false
                                            )
                                        }}
                                        label="Geschlecht"
                                        labelPlacement="fixed"
                                        name="gender"
                                        value={values.gender}
                                        interface="action-sheet"
                                        style={{ maxWidth: '100% !important' }}
                                    >
                                        <IonSelectOption key={'m'} value={'m'}>
                                            männlich
                                        </IonSelectOption>
                                        <IonSelectOption key={'f'} value={'f'}>
                                            weiblich
                                        </IonSelectOption>
                                        <IonSelectOption key={'d'} value={'d'}>
                                            divers
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonItem>
                                    <IonInput
                                        name="birthday"
                                        value={
                                            values.birthday
                                                ? new Date(
                                                      values.birthday
                                                  ).toLocaleString('de-DE', {
                                                      timeZone: 'Europe/Berlin',
                                                      day: '2-digit',
                                                      month: '2-digit',
                                                      year: 'numeric',
                                                  })
                                                : ''
                                        }
                                        label="Geburtstag"
                                        labelPlacement="fixed"
                                        onIonChange={(e) => {
                                            debugger
                                        }}
                                        onIonFocus={(e) => {
                                            console.log(e)
                                            setBirthdayPickerOpen(true)
                                        }}
                                    ></IonInput>
                                </IonItem>
                            </IonList>
                            <IonList inset>
                                <IonListHeader>
                                    <IonLabel>Adressdaten</IonLabel>
                                </IonListHeader>
                                <IonItem>
                                    <IonInput
                                        name="street"
                                        labelPlacement="fixed"
                                        label="Straße"
                                        value={
                                            values.street ? values.street : ''
                                        }
                                        onIonChange={handleChange}
                                    ></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonInput
                                        type="number"
                                        name="houseNumber"
                                        labelPlacement="fixed"
                                        label="Hausnummer"
                                        value={
                                            values.houseNumber
                                                ? values.houseNumber
                                                : ''
                                        }
                                        onIonChange={handleChange}
                                    ></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonInput
                                        type="number"
                                        name="zip"
                                        labelPlacement="fixed"
                                        label="PLZ"
                                        value={values.zip ? values.zip : ''}
                                        onIonChange={handleChange}
                                    ></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonInput
                                        name="city"
                                        labelPlacement="fixed"
                                        label="Stadt"
                                        value={values.city ? values.city : ''}
                                        onIonChange={handleChange}
                                    ></IonInput>
                                </IonItem>
                            </IonList>

                            <IonList inset>
                                <IonListHeader>
                                    <IonLabel>Ergänzende Daten</IonLabel>
                                </IonListHeader>
                                <IonItem>
                                    <IonInput
                                        name="occupation"
                                        labelPlacement="fixed"
                                        label="Beruf"
                                        value={
                                            values.occupation
                                                ? values.occupation
                                                : ''
                                        }
                                        onIonChange={handleChange}
                                    ></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonInput
                                        name="hobbies"
                                        labelPlacement="fixed"
                                        label="Hobbies"
                                        value={
                                            values.hobbies ? values.hobbies : ''
                                        }
                                        onIonChange={handleChange}
                                    ></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonTextarea
                                        name="anthropometricData"
                                        labelPlacement="floating"
                                        autoGrow={true}
                                        label="Anthropometrische Daten"
                                        value={
                                            values.anthropometricData
                                                ? values.anthropometricData
                                                : ''
                                        }
                                        onIonChange={handleChange}
                                    ></IonTextarea>
                                </IonItem>
                            </IonList>

                            <IonList inset>
                                <IonItem
                                    button
                                    detail={false}
                                    onClick={submitForm}
                                >
                                    <IonLabel color="primary">
                                        Speichern
                                    </IonLabel>
                                </IonItem>
                            </IonList>

                            <IonModal
                                id="birthday-picker"
                                isOpen={birthdayPickerOpen}
                                onDidDismiss={() => {
                                    setBirthdayPickerOpen(false)
                                }}
                            >
                                <IonDatetime
                                    showDefaultButtons
                                    presentation="date"
                                    formatOptions={{
                                        date: {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                        },
                                    }}
                                    doneText="Speichern"
                                    cancelText="Abbrechen"
                                    onIonChange={(e) => {
                                        setFieldValue(
                                            'birthday',
                                            new Date(e.detail.value as string)
                                                .toISOString()
                                                .split('T')[0]
                                        )
                                    }}
                                />
                            </IonModal>
                        </>
                    )}
                </Formik>
            </IonContent>
        </IonPage>
    )
}

export default PatientEditPage
