import React, { JSX, useCallback, useEffect, useState } from 'react'
import { IonPage, SearchbarCustomEvent } from '@ionic/react'
import { useParams } from 'react-router-dom'
import { searchIcf } from '../../utils/IcfIcdHandler'
import { SearchResultIcf } from '../../utils/IcfIcdType'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectCaseById, updateCase } from '../Case/CaseSlice'
import { useHistory } from 'react-router'
import IcfSearchAndSelection from './IcfSearchAndSelection'

const IcfSelection = (): JSX.Element => {
    const { patientId, caseId } = useParams<{
        patientId: string
        caseId: string
    }>()
    const caseById = useAppSelector((state) => selectCaseById(state, +caseId))
    const [caseIcfs, setCaseIcfs] = useState<string[]>(caseById?.caseIcfs ?? [])
    const [searchResult, setSearchResult] = useState<SearchResultIcf>({
        chapter: [],
        block: [],
        fourdigit: [],
        fivedigit: [],
        sixdigit: [],
        items: [],
        itemCount: 0,
        separationIndices: [],
    })
    const [submitting, setSubmitting] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const history = useHistory()

    const handleCheckboxSelection = useCallback(
        (icf: string) => {
            let out = [...caseIcfs]
            const index = caseIcfs.indexOf(icf)

            if (index > -1) {
                out.splice(index, 1)
            } else {
                out.push(icf)
            }

            setCaseIcfs(out)
        },
        [caseIcfs]
    )

    const handleSearch = useCallback((e: SearchbarCustomEvent) => {
        let filter = searchIcf(e.detail.value ?? '')
        setSearchResult(filter)
    }, [])

    const handleSubmit = useCallback(async () => {
        setSubmitting(true)
        await dispatch(
            updateCase({
                data: { ...caseById, caseIcfs: caseIcfs },
                id: caseId,
                patientId,
            })
        )
        setSubmitting(false)
        history.push(`/patients/${patientId}/cases/${caseId}`)
    }, [caseById, caseIcfs, caseId, dispatch, history, patientId])

    useEffect(() => {
        let filter = searchIcf('')
        setSearchResult(filter)
    }, [])

    useEffect(() => {
        setCaseIcfs(caseById?.caseIcfs ?? [])
    }, [caseById?.caseIcfs])

    return (
        <IonPage>
            <IcfSearchAndSelection
                hrefBackButton={`/patients/${patientId}/cases/${caseId}`}
                handleSearch={handleSearch}
                searchResult={searchResult}
                caseIcfs={caseIcfs}
                handleCheckboxSelection={handleCheckboxSelection}
                handleSubmit={handleSubmit}
                submitting={submitting}
            />
        </IonPage>
    )
}

export default IcfSelection
