import React, { JSX, memo } from 'react'
import {
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonText,
} from '@ionic/react'
import { EntityId } from '@reduxjs/toolkit'
import { useAppSelector } from '../../hooks'
import { selectCaseById } from '../Case/CaseSlice'
import { isEmpty } from 'lodash'
import { addOutline } from 'ionicons/icons'
import { useRouteMatch } from 'react-router-dom'

interface AnamnesticCaseAnalysisOverviewProps {
    caseId: EntityId
}

const AnamnesticCaseAnalysisOverview = ({
    caseId,
}: AnamnesticCaseAnalysisOverviewProps): JSX.Element => {
    const caseById = useAppSelector((state) => selectCaseById(state, +caseId))
    const match = useRouteMatch()

    return (
        <>
            <IonList inset>
                <IonListHeader>
                    <IonLabel>Anamnestische Fallanalyse</IonLabel>
                    {!caseById?.anamnesticCaseAnalysis && (
                        <>
                            <IonButton
                                routerLink={`${match.url}/anamnesticcaseanalysis`}
                                className="ion-hide-sm-down"
                            >
                                Analyse anlegen
                            </IonButton>
                            <IonButton
                                routerLink={`${match.url}/anamnesticcaseanalysis`}
                                className="ion-hide-sm-up"
                            >
                                <IonIcon slot="icon-only" icon={addOutline} />
                            </IonButton>
                        </>
                    )}
                </IonListHeader>
                {isEmpty(caseById?.anamnesticCaseAnalysis) ? (
                    <div className="ion-text-center ion-padding ion-margin">
                        <IonText>
                            Keine anamnestische Fallanalyse vorhanden
                        </IonText>
                    </div>
                ) : (
                    <>
                        <IonItem>
                            <IonLabel>
                                <IonText>
                                    {caseById.anamnesticCaseAnalysis}
                                </IonText>
                            </IonLabel>
                        </IonItem>
                        <IonItem
                            button
                            detail={false}
                            routerLink={`${match.url}/anamnesticcaseanalysis`}
                        >
                            <IonLabel color="primary">
                                Analyse bearbeiten
                            </IonLabel>
                        </IonItem>
                    </>
                )}
            </IonList>
        </>
    )
}

export default memo(AnamnesticCaseAnalysisOverview)
