import React, { JSX, useCallback, useEffect, useState } from 'react'
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
    createCase,
    fetchCaseById,
    selectCaseById,
    updateCase,
} from '../Case/CaseSlice'
import { Form, Formik, FormikValues } from 'formik'
import { useHistory } from 'react-router'
import { informationCircleOutline } from 'ionicons/icons'
import AnamnesticCaseAnalysisInfoModal from './AnamnesticCaseAnalysisInfoModal'
import { remove } from 'lodash'
import { CheckboxChangeEventDetail, IonCheckboxCustomEvent } from '@ionic/core'

type InfoType =
    | 'mainProblem'
    | 'description'
    | 'chronology'
    | 'contextfactors'
    | 'riskfactors'
    | 'aims'

const AnamnesticCaseAnalysisEdit = (): JSX.Element => {
    const { patientId, caseId } = useParams<{
        patientId: string
        caseId: string
    }>()
    const caseById = useAppSelector((state) => selectCaseById(state, +caseId))
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [infoOpen, setInfoOpen] = useState<boolean>(false)
    const [infoType, setInfoType] = useState<InfoType | null>(null)

    const isNew = caseId === 'new' || !caseById?.anamnesticCaseAnalysis

    const dismissInfoOpen = useCallback(() => {
        setInfoOpen(false)
    }, [])

    const handleInfoClick = useCallback((type: InfoType) => {
        setInfoType(type)
        setInfoOpen(true)
    }, [])

    const getCriteria = useCallback(
        (
            criteria: string[],
            e: IonCheckboxCustomEvent<CheckboxChangeEventDetail>
        ) => {
            if (e.detail.checked) {
                if (!criteria.includes(e.target.name)) {
                    criteria.push(e.target.name)
                }
            } else {
                if (criteria.includes(e.target.name)) {
                    remove(criteria, (item) => e.target.name === item)
                }
            }

            return criteria
        },
        []
    )

    const handleSubmitForm = useCallback(
        async (values: FormikValues) => {
            if (isNew) {
                const response = await dispatch(
                    createCase({
                        data: values,
                        patientId,
                    })
                ).unwrap()
                history.replace(
                    `/patients/${patientId}/cases/${
                        Object.keys(response.entities.case)[0]
                    }`
                )
            } else {
                await dispatch(
                    updateCase({ data: values, id: caseId, patientId })
                )
                history.push(`/patients/${patientId}/cases/${caseId}`)
            }
        },
        [caseId, dispatch, history, isNew, patientId]
    )

    useEffect(() => {
        dispatch(fetchCaseById({ id: caseId, patientId }))
    }, [caseId, dispatch, patientId])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar mode="ios">
                    <IonButtons slot="start">
                        <IonBackButton
                            text=""
                            defaultHref={`/patients/${patientId}/cases/${caseId}`}
                        ></IonBackButton>
                    </IonButtons>
                    <IonTitle>
                        {isNew
                            ? 'Neue Anamnestische Fallanalyse'
                            : 'Anamnestische Fallanalyse'}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <Formik
                    enableReinitialize
                    initialValues={
                        isNew
                            ? ({
                                  patientId: patientId,
                                  anamnesticCaseAnalysisCriteria: [],
                              } as FormikValues)
                            : { ...caseById }
                    }
                    onSubmit={handleSubmitForm}
                >
                    {({
                        values: { anamnesticCaseAnalysisCriteria: criteria },
                        setFieldValue,
                        values,
                        handleChange,
                        submitForm,
                    }) => (
                        <Form>
                            <IonList inset>
                                <IonListHeader>
                                    <IonLabel>Checkliste</IonLabel>
                                </IonListHeader>
                                <IonItem key="mainProblem">
                                    <IonCheckbox
                                        checked={criteria?.includes(
                                            'mainProblem'
                                        )}
                                        onIonChange={(e) =>
                                            setFieldValue(
                                                'anamnesticCaseAnalysisCriteria',
                                                getCriteria(
                                                    [
                                                        ...values.anamnesticCaseAnalysisCriteria,
                                                    ],
                                                    e
                                                )
                                            )
                                        }
                                        name="mainProblem"
                                        labelPlacement="end"
                                        justify="start"
                                    >
                                        <b>Hauptproblem(e)</b>
                                        <br />
                                        (Leitsymptom)
                                    </IonCheckbox>
                                    <IonButton
                                        size="default"
                                        fill="clear"
                                        slot="end"
                                        onClick={() =>
                                            handleInfoClick('mainProblem')
                                        }
                                    >
                                        <IonIcon
                                            slot="icon-only"
                                            icon={informationCircleOutline}
                                        />
                                    </IonButton>
                                </IonItem>
                                <IonItem>
                                    <IonCheckbox
                                        checked={criteria?.includes(
                                            'description'
                                        )}
                                        onIonChange={(e) =>
                                            setFieldValue(
                                                'anamnesticCaseAnalysisCriteria',
                                                getCriteria(
                                                    [
                                                        ...values.anamnesticCaseAnalysisCriteria,
                                                    ],
                                                    e
                                                )
                                            )
                                        }
                                        name="description"
                                        labelPlacement="end"
                                        justify="start"
                                    >
                                        <b>Symptomverhalten</b>
                                        <br />
                                        der Symptome
                                    </IonCheckbox>

                                    <IonButton
                                        size="default"
                                        fill="clear"
                                        slot="end"
                                        onClick={() =>
                                            handleInfoClick('description')
                                        }
                                    >
                                        <IonIcon
                                            slot="icon-only"
                                            icon={informationCircleOutline}
                                        />
                                    </IonButton>
                                </IonItem>
                                {/* <IonItem> muss noch auf der Datenbank ausgebaut werden
                                <IonCheckbox
                                    checked={criteria?.includes(
                                        'suggestibility'
                                    )}
                                    onIonChange={(e) =>
                                        this.handleCriteriaChange(e, props)
                                    }
                                    labelPlacement="end"
                                    justify="start"
                                    name="suggestibility"
                                >
                                    <b>Beeinflussbarkeit</b>
                                    <br />
                                    der Symptome
                                </IonCheckbox>
                            </IonItem> */}
                                <IonItem>
                                    <IonCheckbox
                                        checked={criteria?.includes(
                                            'chronology'
                                        )}
                                        onIonChange={(e) =>
                                            setFieldValue(
                                                'anamnesticCaseAnalysisCriteria',
                                                getCriteria(
                                                    [
                                                        ...values.anamnesticCaseAnalysisCriteria,
                                                    ],
                                                    e
                                                )
                                            )
                                        }
                                        labelPlacement="end"
                                        justify="start"
                                        name="chronology"
                                    >
                                        <b>Chronologie</b>
                                        <br />
                                        des Hauptproblems
                                    </IonCheckbox>
                                    <IonButton
                                        size="default"
                                        fill="clear"
                                        slot="end"
                                        onClick={() =>
                                            handleInfoClick('chronology')
                                        }
                                    >
                                        <IonIcon
                                            slot="icon-only"
                                            icon={informationCircleOutline}
                                        />
                                    </IonButton>
                                </IonItem>
                                <IonItem>
                                    <IonCheckbox
                                        checked={criteria?.includes(
                                            'riskfactors'
                                        )}
                                        onIonChange={(e) =>
                                            setFieldValue(
                                                'anamnesticCaseAnalysisCriteria',
                                                getCriteria(
                                                    [
                                                        ...values.anamnesticCaseAnalysisCriteria,
                                                    ],
                                                    e
                                                )
                                            )
                                        }
                                        labelPlacement="end"
                                        justify="start"
                                        name="riskfactors"
                                    >
                                        <b>Risikofaktoren</b>
                                    </IonCheckbox>
                                    <IonButton
                                        size="default"
                                        fill="clear"
                                        slot="end"
                                        onClick={() =>
                                            handleInfoClick('riskfactors')
                                        }
                                    >
                                        <IonIcon
                                            slot="icon-only"
                                            icon={informationCircleOutline}
                                        />
                                    </IonButton>
                                </IonItem>
                                {/* <IonItem> ausgeblendet, muss noch in der Datenbank dann weg gemacht werden
                                <IonCheckbox
                                    checked={criteria?.includes('comorbidity')}
                                    onIonChange={(e) =>
                                        this.handleCriteriaChange(e, props)
                                    }
                                    labelPlacement="end"
                                    justify="start"
                                    name="comorbidity"
                                >
                                    <b>Komorbidität</b>
                                </IonCheckbox>
                            </IonItem> */}
                                <IonItem>
                                    <IonCheckbox
                                        checked={criteria?.includes(
                                            'contextfactors'
                                        )}
                                        onIonChange={(e) =>
                                            setFieldValue(
                                                'anamnesticCaseAnalysisCriteria',
                                                getCriteria(
                                                    [
                                                        ...values.anamnesticCaseAnalysisCriteria,
                                                    ],
                                                    e
                                                )
                                            )
                                        }
                                        labelPlacement="end"
                                        justify="start"
                                        name="contextfactors"
                                    >
                                        <b>Kontextfaktoren</b>
                                    </IonCheckbox>
                                    <IonButton
                                        size="default"
                                        fill="clear"
                                        slot="end"
                                        onClick={() =>
                                            handleInfoClick('contextfactors')
                                        }
                                    >
                                        <IonIcon
                                            slot="icon-only"
                                            icon={informationCircleOutline}
                                        />
                                    </IonButton>
                                </IonItem>
                                <IonItem>
                                    <IonCheckbox
                                        checked={criteria?.includes('aims')}
                                        onIonChange={(e) =>
                                            setFieldValue(
                                                'anamnesticCaseAnalysisCriteria',
                                                getCriteria(
                                                    [
                                                        ...values.anamnesticCaseAnalysisCriteria,
                                                    ],
                                                    e
                                                )
                                            )
                                        }
                                        labelPlacement="end"
                                        justify="start"
                                        name="aims"
                                    >
                                        <b>Ziele</b>
                                        <br />
                                        des Patienten
                                    </IonCheckbox>
                                    <IonButton
                                        size="default"
                                        fill="clear"
                                        slot="end"
                                        onClick={() => handleInfoClick('aims')}
                                    >
                                        <IonIcon
                                            slot="icon-only"
                                            icon={informationCircleOutline}
                                        />
                                    </IonButton>
                                </IonItem>
                            </IonList>
                            <IonList inset>
                                <IonListHeader>
                                    <IonLabel>Fallanalyse</IonLabel>
                                </IonListHeader>
                                <IonItem>
                                    <IonTextarea
                                        name="anamnesticCaseAnalysis"
                                        autoGrow={true}
                                        onIonChange={handleChange}
                                        label="Anamnestische Fallanalyse"
                                        labelPlacement="floating"
                                        value={
                                            values.anamnesticCaseAnalysis ?? ''
                                        }
                                    ></IonTextarea>
                                </IonItem>

                                <IonItem>
                                    <IonTextarea
                                        name="anamnesticCaseAnalysisHint"
                                        autoGrow={true}
                                        onIonChange={handleChange}
                                        label="Relevante Hinweise aus der Anamnese und Planung der weiteren Untersuchung"
                                        labelPlacement="floating"
                                        value={
                                            values.anamnesticCaseAnalysisHint ??
                                            ''
                                        }
                                    ></IonTextarea>
                                </IonItem>
                            </IonList>
                            <IonList inset>
                                <IonItem
                                    button
                                    detail={false}
                                    onClick={submitForm}
                                >
                                    <IonLabel color="primary">
                                        Speichern
                                    </IonLabel>
                                </IonItem>
                            </IonList>
                        </Form>
                    )}
                </Formik>
                {infoType && (
                    <AnamnesticCaseAnalysisInfoModal
                        open={infoOpen}
                        onDismiss={dismissInfoOpen}
                        infoType={infoType}
                    />
                )}
            </IonContent>
        </IonPage>
    )
}

export default AnamnesticCaseAnalysisEdit
