import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'

export const selectSessionData = createSelector(
    (state: RootState) => state.session,
    (session) => ({
        role: session?.user?.role,
        loggedIn: session?.loggedIn,
        loading: session?.loading,
    })
)
