import React, { JSX } from 'react'
import { EntityId } from '@reduxjs/toolkit'
import { IonChip, IonItem, IonLabel, IonText } from '@ionic/react'
import { useAppSelector } from '../../hooks'
import { selectDocumentationById } from './DocumentationSlice'
import moment from 'moment/moment'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

interface DocumentationItemProps {
    documentationId: EntityId
}

const DocumentationItem = ({
    documentationId,
}: DocumentationItemProps): JSX.Element => {
    const documentation = useAppSelector((state) =>
        selectDocumentationById(state, +documentationId)
    )
    const history = useHistory()
    const match = useRouteMatch()

    return (
        <IonItem
            button
            onClick={() =>
                history.push(`${match.url}/documentations/${documentation.id}`)
            }
        >
            <IonLabel>
                <IonText>{moment(documentation.date).format('llll')}</IonText>
                {!isEmpty(documentation.documentationIcfs) && (
                    <div style={{ paddingTop: '8px' }}>
                        {documentation.documentationIcfs.map((icf: string) => (
                            <IonChip key={icf} color="tertiary">
                                {icf}
                            </IonChip>
                        ))}
                    </div>
                )}
            </IonLabel>
        </IonItem>
    )
}

export default DocumentationItem
