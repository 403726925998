import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'
import CaseLoader from './components/CaseLoader'

const Case = (): JSX.Element => {
    const { path } = useRouteMatch()

    return (
        <IonRouterOutlet>
            <Route path={`${path}/:caseId`} component={CaseLoader} />
        </IonRouterOutlet>
    )
}

export default Case
