import React, { JSX } from 'react'
import {
    IonButton,
    IonIcon,
    IonLabel,
    IonList,
    IonListHeader,
    IonText,
} from '@ionic/react'
import { EntityId } from '@reduxjs/toolkit'
import { addOutline } from 'ionicons/icons'
import { useRouteMatch } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { selectDocumentationsByCase } from '../Case/selectors'
import DocumentationItem from './DocumentationItem'
import { isEmpty } from 'lodash'

interface DocumentationOverviewProps {
    caseId: EntityId
    disabled: boolean
}

const DocumentationOverview = ({
    caseId,
    disabled,
}: DocumentationOverviewProps): JSX.Element => {
    const match = useRouteMatch()
    const documentations = useAppSelector((state) =>
        selectDocumentationsByCase(state, +caseId)
    )

    return (
        <IonList inset>
            <IonListHeader>
                <IonLabel>Dokumentationen</IonLabel>
                <>
                    <IonButton
                        routerLink={`${match.url}/documentations/new`}
                        className="ion-hide-sm-down"
                        disabled={disabled}
                    >
                        Dokumentation hinzufügen
                    </IonButton>
                    <IonButton
                        routerLink={`${match.url}/documentations/new`}
                        className="ion-hide-sm-up"
                        disabled={disabled}
                    >
                        <IonIcon slot="icon-only" icon={addOutline} />
                    </IonButton>
                </>
            </IonListHeader>
            {!documentations || isEmpty(documentations) ? (
                <div className="ion-text-center ion-padding ion-margin">
                    <IonText>Keine Dokumentation angelegt</IonText>
                </div>
            ) : (
                documentations.map((documentation) => (
                    <DocumentationItem
                        documentationId={documentation.id}
                        key={documentation.id}
                    />
                ))
            )}
        </IonList>
    )
}

export default DocumentationOverview
