import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonModal,
    IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import React, { JSX, memo, ReactNode } from 'react'

interface AnamnesticCaseAnalysisInfoModalProps {
    open: boolean
    onDismiss: () => void
    infoType:
        | 'mainProblem'
        | 'description'
        | 'chronology'
        | 'contextfactors'
        | 'riskfactors'
        | 'aims'
}

const infos: Record<string, { header: string; message: ReactNode }> = {
    mainProblem: {
        header: 'Hauptproblem',
        message: (
            <IonText>
                Hauptprobleme zum jetzigen Zeitpunkt bzgl. Körperfunktion,
                Aktivität und Partizipation
            </IonText>
        ),
    },
    description: {
        header: 'Symptomverhalten',
        message: (
            <>
                <IonText>
                    Subjektive Beschreibung der Beschwerden (über 24 Stunden
                    hinweg)
                </IonText>
                <IonText>
                    Subjektive Intensität (unter Belastung/Entlastung)
                </IonText>
                <IonText>Was verstärkt oder lindert die Symptome?</IonText>
                <IonText>
                    Prädisponierende Faktoren im Verhalten wie neue Aktivitäten,
                    Überlastung
                </IonText>
                <IonText>
                    „Wie schränkt ihr Problem sie aus Ihrer Sicht im Alltag ein“
                </IonText>
            </>
        ),
    },
    chronology: {
        header: 'Chronologie',
        message: (
            <>
                <IonText>
                    Auslöser/Lindern der Beschwerden (onset/offset)
                </IonText>
                <IonText>Behandlungen/Untersuchungen</IonText>
                <IonText>Heilungsverlauf (erwartungsgemäß, verzögert?)</IonText>
                <IonText>
                    Zustand vor Beginn der Erkrankung oder der akuten Episode
                </IonText>
                <IonText>
                    Fragen zur Beurteilung des Stadiums und der Stabilität des
                    Problems
                </IonText>
            </>
        ),
    },
    contextfactors: {
        header: 'Kontextfaktoren',
        message: (
            <>
                <IonText>Allgemeine Belastungen, psychische Belastung</IonText>
                <IonText>
                    Coping (Was will der Patient/die Patientin zur Genesung
                    beitragen?)
                </IonText>
                <IonText>
                    Yellow Flags: Kontrollüberzeugung, Katastrophisierung etc.
                </IonText>
                <IonText>Einstellungen des Patienten, Gewohnheiten</IonText>
                <IonText>Persönlichkeit (Eichhörnchen oder Bär)</IonText>
                <IonText>Barrieren in der Lebensumgebung</IonText>
                <IonText>Beruf</IonText>
                <IonText>Hilfsmittel/Hilfe durch Personen</IonText>
            </>
        ),
    },
    riskfactors: {
        header: 'Risikofaktoren',
        message: (
            <>
                <IonText>Nebenerkrankungen</IonText>
                <IonText>Komorbiditäten</IonText>
                <IonText>Komplikationen</IonText>
                <IonText>Red Flags</IonText>
                <IonText>Allg. Gesundheitszustand</IonText>
                <IonText>Pathologien</IonText>
                <IonText>Ungewollter Gewichtsverlust</IonText>
                <IonText>Medikamente</IonText>
                <IonText>Psychosoziale Risikofaktoren</IonText>
            </>
        ),
    },
    aims: {
        header: 'Ziele',
        message: (
            <>
                <IonText>
                    Wann wäre die Therapie für diesen Patienten erfolgreich?
                </IonText>
                <IonText>Woran möchte er wieder teilhaben können?</IonText>
            </>
        ),
    },
}

const AnamnesticCaseAnalysisInfoModal = ({
    open,
    onDismiss,
    infoType,
}: AnamnesticCaseAnalysisInfoModalProps): JSX.Element => {
    return (
        <IonModal isOpen={open} onDidDismiss={onDismiss} aria-modal>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Details</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={onDismiss}>Schließen</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonText>
                    <h3>{infos[infoType].header}</h3>
                </IonText>
                <IonText>
                    <p>{infos[infoType].message}</p>
                </IonText>
            </IonContent>
        </IonModal>
    )
}

export default memo(AnamnesticCaseAnalysisInfoModal)
