import React from 'react'
import RoleEnum from '../../utils/Enum/RoleEnum'
import { useAppSelector } from '../../hooks'
import { Redirect } from 'react-router'
import { selectSessionData } from './selectors'

type AclProps = {
    allowedRoles: Array<`${RoleEnum}`>
    children: React.ReactNode
    silent?: boolean
}

const ACL = ({
    allowedRoles,
    children,
    silent = false,
}: AclProps): JSX.Element => {
    const {
        role: userRole,
        loggedIn,
        loading,
    } = useAppSelector(selectSessionData)

    if (loading) return <></> // Während des Ladens wird nichts gerendert

    const isAuthorized = userRole && allowedRoles.includes(userRole)

    if (isAuthorized && loggedIn) {
        return <>{children}</> // Rendert die übergebenen Kinder, wenn autorisiert
    }

    if (!silent) {
        console.warn('ACL redirect to login')
        return <Redirect to="/login" />
    }

    return <></> // Kein Rendern, wenn silent = true
}

export default ACL
