import React, { forwardRef, JSX, useCallback, useEffect, useRef } from 'react'
import {
    IonBackButton,
    IonButtons,
    IonChip,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { Form, Formik, FormikProps, FormikValues } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import {
    createTest,
    fetchTestById,
    selectTestById,
    updateTest,
} from './TestSlice'
import { useAppDispatch, useAppSelector } from '../../hooks'
import TestIcfList from './TestIcfList'
import IcfAddModal from './IcfAddModal'
import { Test } from './TestType'

type TestForm = Partial<Test & { tagsInput: string }>

const FormikWithRef = forwardRef<FormikProps<TestForm>, any>((props, ref) => (
    <Formik innerRef={ref} {...props} />
))

const TestEdit = (): JSX.Element => {
    const { testId } = useParams<{ testId: string | undefined }>()
    const dispatch = useAppDispatch()
    const history = useHistory()
    const testById = useAppSelector((state) =>
        testId ? selectTestById(state, +testId) : null
    )
    const [modalOpen, setModalOpen] = React.useState(false)
    const formikRef = useRef<FormikProps<TestForm>>(null)

    useEffect(() => {
        if (testId) {
            dispatch(fetchTestById({ id: +testId }))
        }
    }, [dispatch, testId])

    const handleSubmitForm = async (values: FormikValues) => {
        if (testId) {
            await dispatch(updateTest({ data: values, id: testId }))
        } else {
            await dispatch(createTest({ data: values }))
        }

        history.push('/tests')
    }

    const handleCheckboxSelection = useCallback((icf: string) => {
        if (!formikRef.current) {
            return
        }
        const { values, setFieldValue } = formikRef.current

        let out = [...(values.testIcfs ?? [])]
        const index = out.indexOf(icf)

        if (index > -1) {
            out.splice(index, 1)
        } else {
            out.push(icf)
        }

        setFieldValue('testIcfs', out)
    }, [])

    const initialValues: TestForm = testId
        ? {
              ...testById,
              tagsInput: testById?.tags.join(', '),
          }
        : ({ testIcfs: [] } as Partial<Test>)

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            text=""
                            defaultHref={`/tests/`}
                        ></IonBackButton>
                    </IonButtons>
                    <IonTitle>
                        {testId
                            ? `${testById?.name ?? 'Test'} bearbeiten`
                            : 'Neuen Test anlegen'}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <FormikWithRef
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    enableReinitialize
                    ref={formikRef}
                >
                    {({
                        values,
                        handleChange,
                        setFieldValue,
                        isSubmitting,
                        submitForm,
                    }: FormikProps<TestForm>) => (
                        <Form>
                            <IonList inset>
                                <IonListHeader>
                                    <IonLabel>Allgemeine Angaben</IonLabel>
                                </IonListHeader>
                                <IonItem>
                                    <IonInput
                                        name="name"
                                        value={values['name'] ?? ''}
                                        onIonChange={handleChange}
                                        labelPlacement="fixed"
                                        label="Name"
                                    />
                                </IonItem>
                                <IonItem>
                                    <IonInput
                                        name="abbreviation"
                                        value={values['abbreviation'] ?? ''}
                                        onIonChange={handleChange}
                                        labelPlacement="fixed"
                                        label="Abkürzung"
                                    />
                                </IonItem>
                                <IonItem>
                                    <IonTextarea
                                        name="description"
                                        label="Beschreibung"
                                        value={values['description'] ?? ''}
                                        onIonChange={handleChange}
                                        labelPlacement="fixed"
                                        autoGrow
                                    />
                                </IonItem>
                                <IonItem>
                                    <IonSelect
                                        placeholder="Bitte auswählen"
                                        label="Assesment Klassifikation"
                                        name="assessmentClassification"
                                        labelPlacement="floating"
                                        onIonChange={(e) => {
                                            setFieldValue(
                                                'assessmentClassification',
                                                e.detail.value,
                                                false
                                            )
                                        }}
                                        value={values.assessmentClassification}
                                    >
                                        <IonSelectOption value="aktiv">
                                            Aktiver Test
                                        </IonSelectOption>
                                        <IonSelectOption value="passiv">
                                            Passiver Test
                                        </IonSelectOption>
                                        <IonSelectOption value="fragebogen">
                                            Fragebogen
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonItem>
                                    <IonSelect
                                        placeholder="Bitte auswählen"
                                        label="Fachgebiet"
                                        labelPlacement="floating"
                                        name="specialistArea"
                                        onIonChange={(e) => {
                                            setFieldValue(
                                                'specialistArea',
                                                e.detail.value,
                                                false
                                            )
                                        }}
                                        value={values.specialistArea}
                                    >
                                        <IonSelectOption value="Allgemein">
                                            Allgemein
                                        </IonSelectOption>
                                        <IonSelectOption value="Chirurgie">
                                            Chirurgie
                                        </IonSelectOption>
                                        <IonSelectOption value="Orthopädie">
                                            Orthopädie
                                        </IonSelectOption>
                                        <IonSelectOption value="Geriatrie">
                                            Geriatrie
                                        </IonSelectOption>
                                        <IonSelectOption value="Innere Medizin">
                                            Innere Medizin
                                        </IonSelectOption>
                                        <IonSelectOption value="Gynäkologie">
                                            Gynäkologie
                                        </IonSelectOption>
                                        <IonSelectOption value="Pädiatrie">
                                            Pädiatrie
                                        </IonSelectOption>
                                        <IonSelectOption value="Neurologie">
                                            Neurologie
                                        </IonSelectOption>
                                        <IonSelectOption value="Sportmedizin">
                                            Sportmedizin
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                            </IonList>
                            <IonList inset>
                                <IonListHeader>
                                    <IonLabel>Tags</IonLabel>
                                </IonListHeader>
                                <IonItem lines="none">
                                    <IonTextarea
                                        name="tags"
                                        value={values['tagsInput'] ?? ''} // Eingabefeld zeigt immer die aktuelle Liste + neue Eingabe
                                        labelPlacement="floating"
                                        autoGrow
                                        label="Tags"
                                        helperText="Kommagetrennte Liste"
                                        placeholder="tag1, tag2, tag3"
                                        onIonChange={(e) => {
                                            const inputValue =
                                                e.detail.value ?? ''
                                            const out = inputValue
                                                .split(',')
                                                .map((item) => item.trim())
                                                .filter((item) => item !== '')

                                            const updatedTags = Array.from(
                                                new Set(out) // Verhindere Duplikate
                                            )
                                            setFieldValue('tags', updatedTags)

                                            setFieldValue(
                                                'tagsInput',
                                                updatedTags.join(', ')
                                            )
                                        }}
                                        onIonInput={(e) => {
                                            const inputValue =
                                                e.detail.value ?? ''
                                            if (inputValue.slice(-1) === ',') {
                                                const out = inputValue
                                                    .split(',')
                                                    .map((item) => item.trim())
                                                    .filter(
                                                        (item) => item !== ''
                                                    )

                                                const updatedTags = Array.from(
                                                    new Set(out) // Verhindere Duplikate
                                                )
                                                setFieldValue(
                                                    'tags',
                                                    updatedTags
                                                )

                                                setFieldValue(
                                                    'tagsInput',
                                                    updatedTags.join(', ') + ','
                                                )
                                            }
                                        }}
                                    />
                                </IonItem>
                                {values['tags'] &&
                                    values['tags'].length > 0 && (
                                        <IonItem>
                                            <IonLabel>
                                                {values['tags'].map(
                                                    (
                                                        tag: string,
                                                        index: number
                                                    ) => (
                                                        <IonChip key={index}>
                                                            {tag}
                                                        </IonChip>
                                                    )
                                                )}
                                            </IonLabel>
                                        </IonItem>
                                    )}
                            </IonList>
                            <IonList inset>
                                <IonListHeader>
                                    <IonLabel>Details</IonLabel>
                                </IonListHeader>
                                <IonItem lines="none">
                                    <IonInput
                                        type="number"
                                        name="specificity"
                                        onIonInput={(e: CustomEvent) => {
                                            let inputValue = parseInt(
                                                e.detail.value || '',
                                                10
                                            )
                                            if (!isNaN(inputValue)) {
                                                inputValue = Math.min(
                                                    100,
                                                    Math.max(1, inputValue)
                                                ) // Begrenzung
                                                setFieldValue(
                                                    'specificity',
                                                    inputValue
                                                )
                                            }
                                        }}
                                        helperText="1-100"
                                        value={values['specificity'] ?? 1}
                                        label="Spezifität"
                                        labelPlacement="fixed"
                                    ></IonInput>
                                </IonItem>
                                <IonItem lines="none">
                                    <IonInput
                                        type="number"
                                        name="sensitivity"
                                        onIonInput={(e: CustomEvent) => {
                                            let inputValue = parseInt(
                                                e.detail.value || '',
                                                10
                                            )
                                            if (!isNaN(inputValue)) {
                                                inputValue = Math.min(
                                                    100,
                                                    Math.max(1, inputValue)
                                                ) // Begrenzung
                                                setFieldValue(
                                                    'sensitivity',
                                                    inputValue
                                                )
                                            }
                                        }}
                                        helperText="1-100"
                                        value={values['sensitivity'] ?? 1}
                                        label="Sensitivität"
                                        labelPlacement="fixed"
                                    ></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonInput
                                        name="durationOfEvaluation"
                                        type="number"
                                        value={
                                            values['durationOfEvaluation'] ?? ''
                                        }
                                        onIonChange={handleChange}
                                        label="Auswertungsdauer in Sekunden"
                                    ></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonInput
                                        name="durationOfExecution"
                                        value={
                                            values['durationOfExecution'] ?? ''
                                        }
                                        onIonChange={handleChange}
                                        type="number"
                                        label="Ausführungsdauer in Sekunden"
                                    ></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonInput
                                        name="url"
                                        value={values['url'] ?? ''}
                                        onIonChange={handleChange}
                                        type="url"
                                        labelPlacement="fixed"
                                        label="URL"
                                    />
                                </IonItem>
                            </IonList>
                            <TestIcfList
                                onAdd={() => setModalOpen(true)}
                                icfList={values.testIcfs}
                            />
                            <IcfAddModal
                                open={modalOpen}
                                onClose={() => setModalOpen(false)}
                                onCheckboxSelection={handleCheckboxSelection}
                                selectedIcfs={values.testIcfs ?? []}
                            />
                        </Form>
                    )}
                </FormikWithRef>
            </IonContent>
        </IonPage>
    )
}

export default TestEdit
