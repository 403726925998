import React, { JSX, useCallback, useState } from 'react'
import {
    IonButton,
    IonIcon,
    IonLabel,
    IonList,
    IonListHeader,
    IonText,
} from '@ionic/react'
import { addOutline } from 'ionicons/icons'
import { isEmpty } from 'lodash'
import IcfIcdItem from '../../components/IcfIcd/IcfIcdItem'
import IcfIcdInfoModal from '../../components/IcfIcd/IcfIcdInfoModal'
import { IcfIcdEntry } from '../../utils/IcfIcdType'
import { useFormikContext } from 'formik'
import { Test } from './TestType'

interface TestIcfListProps {
    onAdd: () => void
    icfList?: string[]
}

const TestIcfListPage = ({ onAdd, icfList }: TestIcfListProps): JSX.Element => {
    const [selectedIcf, setSelectedIcf] = useState<IcfIcdEntry | null>(null)
    const [infoModalOpen, setInfoModalOpen] = React.useState(false)
    const { setFieldValue, values } = useFormikContext<Test>()

    const handleInfoOpen = useCallback((icf: IcfIcdEntry) => {
        setSelectedIcf(icf)
        setInfoModalOpen(true)
    }, [])

    const handleDelete = useCallback(
        (icf: IcfIcdEntry) => {
            setFieldValue(
                'testIcfs',
                values.testIcfs.filter((item) => item !== icf.code)
            )
        },
        [setFieldValue, values.testIcfs]
    )

    return (
        <>
            <IonList inset>
                <IonListHeader>
                    <IonLabel>ICF</IonLabel>
                    <IonButton className="ion-hide-sm-down" onClick={onAdd}>
                        ICF hinzufügen
                    </IonButton>
                    <IonButton className="ion-hide-sm-up" onClick={onAdd}>
                        <IonIcon slot="icon-only" icon={addOutline} />
                    </IonButton>
                </IonListHeader>
                {isEmpty(icfList) || !icfList ? (
                    <div className="ion-text-center ion-padding ion-margin">
                        <IonText>Keine ICFs ausgewählt</IonText>
                    </div>
                ) : (
                    <>
                        {icfList.map((icf) => (
                            <IcfIcdItem
                                key={icf}
                                entry={icf}
                                onInfo={handleInfoOpen}
                                onDelete={handleDelete}
                            />
                        ))}
                    </>
                )}
            </IonList>
            <IcfIcdInfoModal
                open={infoModalOpen}
                icd={selectedIcf}
                onDismiss={() => {
                    setInfoModalOpen(false)
                }}
            />
        </>
    )
}

export default TestIcfListPage
