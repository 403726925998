import React from 'react'

import ACL from '../../components/ACL/ACL'
import Case from './Case'
import RoleEnum from '../../utils/Enum/RoleEnum'
import { IonPage } from '@ionic/react'

const CaseIndex = (): JSX.Element => {
    return (
        <IonPage>
            <ACL
                allowedRoles={[
                    RoleEnum.ROLE_USER,
                    RoleEnum.ROLE_MOD,
                    RoleEnum.ROLE_ADMIN,
                ]}
            >
                <Case />
            </ACL>
        </IonPage>
    )
}

export default CaseIndex
