import React from 'react'
import {
    IonButton,
    IonContent,
    IonIcon,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
} from '@ionic/react'
import { Formik } from 'formik'
import { useAppDispatch } from '../../hooks'
import { useHistory } from 'react-router-dom'
import { loginUser } from '../Auth/SessionSlice'
import { logInOutline } from 'ionicons/icons'
import img from '../../assets/med-educate.png'

export type LoginForm = {
    username?: string
    password?: string
}

const Login = (): JSX.Element => {
    const history = useHistory()
    const dispatch = useAppDispatch()

    const handleSubmitForm = async (values: LoginForm) => {
        try {
            const response = await dispatch(loginUser(values))
            if (!response.type.endsWith('rejected')) {
                history.push('/dashboard')
            }
        } catch (error) {
            console.log('login error', error)
        }
    }

    const initialValues = {}

    return (
        <IonPage>
            <IonContent fullscreen color="light">
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                >
                    {({ handleChange, handleSubmit }) => (
                        <form
                            onSubmit={handleSubmit}
                            style={{
                                height: '100%',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <IonList
                                inset
                                style={{
                                    maxWidth: '500px',
                                    width: '100%',
                                }}
                            >
                                <IonImg
                                    src={img}
                                    alt="Header-Bild"
                                    className="ion-padding"
                                />
                                <IonListHeader
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <IonLabel>Login</IonLabel>
                                </IonListHeader>
                                <IonItem>
                                    <IonInput
                                        name="email"
                                        label="E-Mail"
                                        labelPlacement="floating"
                                        onIonChange={handleChange}
                                    ></IonInput>
                                </IonItem>

                                <IonItem lines="full">
                                    <IonInput
                                        type="password"
                                        name="password"
                                        label="Passwort"
                                        labelPlacement="floating"
                                        onIonChange={handleChange}
                                    ></IonInput>
                                </IonItem>

                                <div
                                    style={{
                                        padding: '20px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <IonButton shape="round" type="submit">
                                        <IonIcon
                                            slot="start"
                                            icon={logInOutline}
                                        ></IonIcon>
                                        Login
                                    </IonButton>
                                </div>
                            </IonList>
                        </form>
                    )}
                </Formik>
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        opacity: 0.3,
                    }}
                >
                    {window.location.hostname}
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Login
