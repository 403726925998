import React from 'react'

import ACL from '../../components/ACL/ACL'
import Test from './Test'
import RoleEnum from '../../utils/Enum/RoleEnum'

const TestIndex = (): JSX.Element => {
    return (
        <ACL
            allowedRoles={[
                RoleEnum.ROLE_USER,
                RoleEnum.ROLE_MOD,
                RoleEnum.ROLE_ADMIN,
            ]}
        >
            <Test />
        </ACL>
    )
}

export default TestIndex
