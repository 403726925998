import {
    EntityId,
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit'
import { Case } from './CaseType'
import { RootState } from '../../store'
import axiosInstance from '../../utils/ApiConfig'
import { normalize, schema } from 'normalizr'
import { Schemas } from '../../schemas'
import { FormikValues } from 'formik'
import { Aim } from '../Aim/AimType'
import { Flag } from '../Flag/FlagType'
import { Documentation } from '../Documentation/DocumentationType'
import { Test } from '../Test/TestType'
import { TestSelection } from '../TestSelection/TestSelectionType'
import { fetchPatientById } from '../Patient/PatientSlice'
import { TestEvaluation } from '../TestEvaluation/TestEvaluationType'
import * as saver from 'file-saver'
import { Inspection } from '../Inspection/InspectionType'

const caseAdapter = createEntityAdapter<Case>({
    sortComparer: (a, b) => a.id - b.id,
})

export const fetchAllCases = createAsyncThunk(
    'case/fetchAll',
    async (params: { patientId: EntityId }) => {
        const { patientId } = params
        const response = await axiosInstance.get(
            `/patients/${patientId}/cases/`
        )
        const normalized = normalize<
            schema.Array<Case>,
            {
                case: Record<string, Case>
            }
        >(response.data.data, Schemas.Cases)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const fetchCaseSummaryById = createAsyncThunk(
    'case/fetchSummaryById',
    async (params: { id: EntityId; patientId: EntityId }) => {
        const { id, patientId } = params

        const response = await axiosInstance.get(
            `/patients/${patientId}/cases/${id}/summary`,
            { responseType: 'blob' }
        )

        const dataBlob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
        saver.saveAs(dataBlob, `Übersicht Fall ${id}.docx`)
    }
)

export const fetchCaseById = createAsyncThunk(
    'case/fetchById',
    async (params: { id: EntityId; patientId: EntityId }) => {
        const { id, patientId } = params

        const response = await axiosInstance.get(
            `/patients/${patientId}/cases/${id}`
        )
        const normalized = normalize<
            schema.Entity<Case>,
            {
                case: Record<string, Case>
                aim: Record<string, Aim>
                flag: Record<string, Flag>
                documentation: Record<string, Documentation>
                test: Record<string, Test>
                testSelection: Record<string, TestSelection>
                testEvaluation: Record<string, TestEvaluation>
                inspection: Record<string, Inspection>
            }
        >(response.data.data, Schemas.Case)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const updateCase = createAsyncThunk(
    'case/update',
    async (params: {
        id: EntityId
        data: FormikValues
        patientId: EntityId
    }) => {
        const { id, data, patientId } = params
        const response = await axiosInstance.put(
            `/patients/${patientId}/cases/${id}`,
            {
                ...data,
            }
        )
        const normalized = normalize<
            schema.Entity<Case>,
            {
                case: Record<string, Case>
            }
        >(response.data.data, Schemas.Case)

        return {
            statusCode: response.status,
            entities: normalized.entities,
            message: response.data.message,
        }
    }
)

export const createCase = createAsyncThunk(
    'case/create',
    async (params: { data: FormikValues; patientId: EntityId }) => {
        const { data, patientId } = params
        const response = await axiosInstance.post(
            `/patients/${patientId}/cases/`,
            {
                ...data,
            }
        )
        debugger
        const normalized = normalize<
            schema.Entity<Case>,
            {
                case: Record<string, Case>
            }
        >(response.data.data, Schemas.Case)

        return { entities: normalized.entities, message: response.data.message }
    }
)

export const deleteCaseById = createAsyncThunk(
    'case/delete',
    async (params: { id: EntityId; patientId: EntityId }) => {
        const { id, patientId } = params

        const response = await axiosInstance.delete(
            `/patients/${patientId}/cases/${id}`
        )

        return { id: id, message: response.data.message }
    }
)

const caseSlice = createSlice({
    name: 'case',
    initialState: caseAdapter.getInitialState({}),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllCases.fulfilled, (state, action) => {
            const { case: thisCase } = action.payload.entities

            if (thisCase) {
                caseAdapter.upsertMany(state, thisCase)
            }
        })
        builder.addCase(fetchCaseById.fulfilled, (state, action) => {
            const { case: thisCase } = action.payload.entities

            if (thisCase) {
                caseAdapter.upsertMany(state, thisCase)
            }
        })
        builder.addCase(updateCase.fulfilled, (state, action) => {
            const { case: thisCase } = action.payload.entities

            if (thisCase) {
                caseAdapter.upsertMany(state, thisCase)
            }
        })
        builder.addCase(createCase.fulfilled, (state, action) => {
            const { case: thisCase } = action.payload.entities

            if (thisCase) {
                caseAdapter.upsertMany(state, thisCase)
            }
        })
        builder.addCase(deleteCaseById.fulfilled, (state, action) => {
            const { id } = action.payload

            caseAdapter.removeOne(state, +id)
        })
        builder.addCase(fetchPatientById.fulfilled, (state, action) => {
            const { case: thisCase } = action.payload.entities

            if (thisCase) {
                caseAdapter.upsertMany(state, thisCase)
            }
        })
    },
})

export const {
    selectAll: selectAllCases,
    selectById: selectCaseById,
    selectIds: selectCaseIds,
} = caseAdapter.getSelectors<RootState>((state) => state.case)

export default caseSlice.reducer
