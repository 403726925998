import React, { JSX, useCallback } from 'react'
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonNote,
    IonPage,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    useIonAlert,
} from '@ionic/react'
import moment from 'moment/moment'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
    createDocumentation,
    deleteDocumentationById,
    selectDocumentationById,
    updateDocumentation,
} from './DocumentationSlice'
import { Form, Formik, FormikValues } from 'formik'
import { fetchCaseById, selectCaseById } from '../Case/CaseSlice'
import { useHistory } from 'react-router'
import IcfItem from './IcfItem'

const DocumentationEdit = (): JSX.Element => {
    const { patientId, caseId, documentationId } = useParams<{
        patientId: string
        caseId: string
        documentationId: string
    }>()
    const isNew = documentationId === 'new'
    const documentation = useAppSelector((state) =>
        selectDocumentationById(state, +documentationId)
    )
    const caseById = useAppSelector((state) => selectCaseById(state, +caseId))
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [presentAlert] = useIonAlert()

    const handleSubmit = useCallback(
        async (values: FormikValues) => {
            if (isNew) {
                await dispatch(
                    createDocumentation({ data: values, caseId, patientId })
                )
            } else {
                await dispatch(
                    updateDocumentation({
                        data: values,
                        id: documentationId,
                        caseId,
                        patientId,
                    })
                )
            }
            await dispatch(fetchCaseById({ id: caseId, patientId }))
            history.push(`/patients/${patientId}/cases/${caseId}`)
        },
        [caseId, dispatch, documentationId, history, isNew, patientId]
    )

    const handleDelete = useCallback(async () => {
        await dispatch(
            deleteDocumentationById({
                id: documentationId,
                patientId,
                caseId,
            })
        )
        history.push(`/patients/${patientId}/cases/${caseId}`)
    }, [caseId, dispatch, documentationId, history, patientId])

    const handleDeleteClick = useCallback(() => {
        presentAlert({
            header: `Wollen Sie die Dokumentation vom ${moment(
                documentation.date
            ).format('L')} wirklich löschen?`,
            buttons: [
                {
                    text: 'Abbrechen',
                    role: 'cancel',
                },
                {
                    text: 'Löschen',
                    role: 'delete',
                    handler: handleDelete,
                },
            ],
        })
    }, [documentation?.date, handleDelete, presentAlert])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton
                            text=""
                            defaultHref={`/patients/${patientId}/cases/${caseId}`}
                        ></IonBackButton>
                    </IonButtons>
                    <IonTitle>
                        {isNew
                            ? 'Neue Dokumentation'
                            : 'Dokumentation vom: ' +
                              moment(documentation?.date).format('L')}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <Formik
                    initialValues={
                        isNew
                            ? ({
                                  date: moment().format(),
                                  documentationIcfs: [],
                              } as FormikValues)
                            : documentation
                    }
                    onSubmit={handleSubmit}
                >
                    {({ handleChange, values, submitForm }) => (
                        <Form>
                            <IonList inset>
                                <IonListHeader>
                                    <IonLabel>ICF-Auswahl</IonLabel>
                                </IonListHeader>
                                {caseById.caseIcfs ? (
                                    caseById.caseIcfs.map((icf: string) => (
                                        <IcfItem icf={icf} key={icf} />
                                    ))
                                ) : (
                                    <div className="ion-text-center ion-padding ion-margin">
                                        <IonText>
                                            Dem Fall sind keine ICFs zugeordnet
                                        </IonText>
                                    </div>
                                )}
                            </IonList>
                            <IonNote
                                color="medium"
                                class="ion-margin-horizontal"
                            >
                                Auf welche ICFs bezieht sich diese
                                Testauswertung (optional)?
                            </IonNote>
                            <IonList inset>
                                <IonListHeader>
                                    <IonLabel>Dokumentation</IonLabel>
                                </IonListHeader>
                                <IonItem>
                                    <IonTextarea
                                        name="text"
                                        label="Dokumentation"
                                        labelPlacement="floating"
                                        autoGrow={true}
                                        onIonChange={handleChange}
                                        value={values.text ?? ''}
                                    ></IonTextarea>
                                </IonItem>
                                <IonItem>
                                    <IonTextarea
                                        name="toDoNext"
                                        label="To Do Next"
                                        labelPlacement="floating"
                                        autoGrow={true}
                                        onIonChange={handleChange}
                                        value={values.toDoNext ?? ''}
                                    ></IonTextarea>
                                </IonItem>
                            </IonList>
                            <IonList inset>
                                <IonItem
                                    button
                                    detail={false}
                                    onClick={submitForm}
                                >
                                    <IonLabel color="primary">
                                        Speichern
                                    </IonLabel>
                                </IonItem>
                            </IonList>

                            {!isNew && (
                                <IonList inset>
                                    <IonItem
                                        button
                                        detail={false}
                                        onClick={handleDeleteClick}
                                    >
                                        <IonLabel color="danger">
                                            Dokumentation löschen
                                        </IonLabel>
                                    </IonItem>
                                </IonList>
                            )}
                        </Form>
                    )}
                </Formik>
            </IonContent>
        </IonPage>
    )
}

export default DocumentationEdit
